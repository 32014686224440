import React, { useState } from 'react';
import '../styles/main.scss'
import Nav from '../components/Navigation'
import Ftr from '../components/Footer'
import { StaticImage } from "gatsby-plugin-image"
import Paper from '@material-ui/core/Paper';
import Fab from '@mui/material/Fab';
// import { AiOutlineCloudDownload } from "@react-icons/all-files/ai/AiOutlineCloudDownload";
import { FiDownload } from "@react-icons/all-files/fi/FiDownload";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import resumePDF from '../static/docs/galvan_rodolfo_resume.pdf'

const Resume = (props) => {
  return (
    <div id="resume">
      <Nav />
      <div className="resume-container">
        <Paper elevation={3} className="pdf-paper">
          <StaticImage
              src="../static/images/galvan_rodolfo_resume.png"
              alt="Rodolfo Galván Resumé Page 1"
              placeholder="blurred"
              layout="fullWidth"
              width={400}
              height={400}
            />
        </Paper>  
      </div>
      <Fab variant="extended" sx={{backgroundColor: '#a53648', position:'fixed', right:'35px', bottom:'35px','&:hover': {backgroundColor: '#b53a4e'}}}>
        <a href={resumePDF} download style={{display: 'flex', flexDirection:'row', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
        {/* <AiOutlineCloudDownload style={{color: 'white', fontSize: '25px'}} /> */}
        <FiDownload style={{color: 'white', fontSize: '20px'}} />
        <p className="download-text">Download</p>
        </ a>
      </Fab>
      <Ftr />
    </div>
  );
};

export default Resume;
